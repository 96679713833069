import { useRouter } from "next/router";
import { useMemo } from "react";

const useIsOpenModalShowDetail = (): boolean => {
  const router = useRouter();
  const { entityId } = router.query;
  const isOpen = useMemo(() => !!entityId, [entityId]);

  return isOpen;
};

export default useIsOpenModalShowDetail;
