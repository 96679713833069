import ProjectShowTemplate from "@/features/project/components/ProjectShowTemplate";
import useProject from "@/features/project/hooks/useProject";
import { FC } from "react";

interface Props {
  id: number;
}

const ProjectWithId: FC<Props> = ({ id }) => {
  const { project, setProject } = useProject(id);

  if (!project) return null;

  return <ProjectShowTemplate project={project} setProject={setProject} />;
};

export default ProjectWithId;
