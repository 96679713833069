import useProduct from "@/features/product/hooks/useProduct";
import { FC } from "react";
import ProductShowTemplate from "@/features/product/components/ProductShowTemplate";

interface Props {
  id: number;
  scrollableTargetId?: string;
}

const ProductWithId: FC<Props> = ({ id, scrollableTargetId }) => {
  const { product, mutate } = useProduct(id);

  if (!product) return null;

  return (
    <ProductShowTemplate
      product={product}
      scrollableTargetId={scrollableTargetId}
      mutate={mutate}
    />
  );
};

export default ProductWithId;
