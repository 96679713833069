import PostShowTemplate from "@/features/post/components/PostShowTemplate";
import usePost from "@/features/post/hooks/usePost";
import { FC } from "react";

interface Props {
  id: number;
  scrollableTargetId?: string;
}

const PostWithId: FC<Props> = ({ id, scrollableTargetId }) => {
  const { post, setPost } = usePost(id);

  if (!post) return null;

  return (
    <PostShowTemplate
      scrollableTargetId={scrollableTargetId}
      post={post}
      setPost={setPost}
    />
  );
};

export default PostWithId;
