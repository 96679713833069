import { useRouter } from "next/router";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import ReactModal from "react-modal";
import { ModalEntityType } from "@/components/LinkAsModal";
import PostWithId from "@/features/post/components/PostWithId";
import ProductWithId from "@/features/product/components/ProductWithId";
import ProjectWithId from "@/features/project/components/ProjectWithId";
import { theme } from "tailwind.config";
import useIsOpenModalShowDetail from "@/hooks/useIsOpenModalShowDetail";

const scrollableTargetId = "ModalShowDetail";

const ModalShowDetail: FC = (): JSX.Element => {
  const router = useRouter();
  const { entityType, entityId } = router.query;
  const numberId = useMemo<number>(
    () => parseInt(entityId as string, 10),
    [entityId],
  );
  const [content, setContent] = useState<HTMLDivElement>();

  const onClose = useCallback(() => {
    const query = Object.assign(router.query, {});
    delete query["entityId"];
    delete query["entityType"];
    delete query["v_id"];

    router.replace(
      {
        pathname: router.pathname,
        query,
      },
      undefined,
      { shallow: true },
    );
  }, [router]);

  const modalStyle = useMemo<ReactModal.Styles>(() => {
    return {
      overlay: {
        position: "fixed",
        inset: 0,
        backgroundColor: "rgba(0,0,0,0.7)",
        zIndex: 9999,
        overflow: "hidden",
      },
      content: {
        position: "relative",
        marginTop: 40,
        marginLeft: 0,
        marginRight: 0,
        height: "calc(100vh - 40px)",
        background: theme.colors.gray[50],
        overflowY: "scroll",
        borderRadius: "8px 8px 0 0",
      },
    };
  }, []);

  useEffect(() => {
    if (content) {
      content.scrollTo({ top: 0 });
      content.focus({ preventScroll: true });
    }
  }, [content, entityId, entityType]);

  const isOpen = useIsOpenModalShowDetail();

  // 閉じている時でもレンダリングさせてしまうと、必ず最下層のモーダルになってしまうのでnullを返しておく
  if (!isOpen) return null;

  return (
    <ReactModal
      id={scrollableTargetId}
      isOpen={isOpen}
      onRequestClose={onClose}
      style={modalStyle}
      onAfterOpen={({ contentEl }) => setContent(contentEl)}
      onAfterClose={() => setContent(undefined)}
      className={{
        base: "duration-300 opacity-0 transition-all ease-out hidden-scrollbar top-120",
        afterOpen: "opacity-100 !top-0",
        beforeClose: "",
      }}
      overlayClassName={{
        base: "duration-500 opacity-0 transition-all linear",
        afterOpen: "opacity-100",
        beforeClose: "",
      }}
      overlayElement={(props, contentElement) => (
        <div {...props}>{contentElement}</div>
      )}
    >
      <header className="sticky top-0 flex justify-end">
        <button className="p-8 text-primary" onClick={onClose}>
          <i className="icon-Close" />
        </button>
      </header>
      {entityType == ModalEntityType.Post && (
        <PostWithId scrollableTargetId={scrollableTargetId} id={numberId} />
      )}
      {entityType == ModalEntityType.Product && (
        <ProductWithId scrollableTargetId={scrollableTargetId} id={numberId} />
      )}
      {entityType == ModalEntityType.Project && <ProjectWithId id={numberId} />}
    </ReactModal>
  );
};

export default ModalShowDetail;
